import Entity from '../entity'
import LookUp from "@/store/entities/lookup";
import DocumentDynamicProperty from "@/store/entities/jobcontext/documentdynamicproperty";

export default class OrderDocument extends Entity<number> {
    shippingOrderId: number;
    documentId: number;
    documentName: string;
    files: Array<LookUp>;
}
