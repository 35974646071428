
import {Component, Prop} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import CustomerShippingOrder from "@/store/entities/ordercontext/customershippingorder";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import OrderDocument from "@/views/setting/OrderContext/Order/order-document.vue";

@Component({
  components: {OrderDocument}
})
export default class OrderCustomerForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;
  @Prop({type: Boolean, default: false}) public toEdit: boolean;

  order: CustomerShippingOrder = new CustomerShippingOrder();
  private isSaveButtonDisabled: boolean = false;
  private companies: any;
  private relatedSuppliers: any;
  private relatedConsignees: any;
  private incoterms: any;
  private isLoading: boolean = false;
  private originLocations: any;
  private destinationLocations: any;
  private shippingMethods: any;
  private documents: [];


  clearSupplier() {
    this.order.isCustomSupplier = false;
    this.order.supplierId = null;
  }

  clearConsignee() {
    this.order.isCustomConsignee = false;
    this.order.consigneeId = null;
  }

  async loadOrigin(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.originLocations = await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, this.order.originId));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.originLocations = [];
    }
  }

  data() {
    return {
      companies: [],
      relatedSuppliers: [],
      relatedConsignees: [],
      destinationLocations: [],
      incoterms: [],
      originLocations: [],
      shippingMethods: [],
      documents: []
    }
  };

  async loadDestination(query) {
    if (query !== null && query.length >= 3) {
      this.isLoading = true;
      this.destinationLocations = await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, this.order.destinationId));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.destinationLocations = [];
    }
  }

  customSupplierChange(val: boolean) {
    if (!val) {
      this.order.customSupplierName = '';
    }
  }

  customConsigneeChange(val: boolean) {
    if (!val) {
      this.order.customConsigneeName = '';
    }
  }

  async isCompanyChange(val: number) {
    (this.$refs.consignee as any).clearSingleSelect();
    (this.$refs.supplier as any).clearSingleSelect();
    await this.loadRelatedCompanies(val);
  }

  async loadCustomerCompanies() {
    this.companies = await this.$store.dispatch('company/customerCompany');
  }

  async loadRelatedCompanies(companyId: number) {
    if (companyId) {
      this.relatedConsignees = await this.$store.dispatch('company/getRelatedCompaniesByCategory', {
        companyId: companyId,
        category: '02'
      });
      this.relatedSuppliers = await this.$store.dispatch('company/getRelatedCompaniesByCategory', {
        companyId: companyId,
        category: '03'
      });
    }
  }

  save() {
    (this.$refs.countryForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.isSaveButtonDisabled = true;
        try {
          await this.$store.dispatch({
            type: this.toEdit ? 'order/updateForCustomer' : 'order/createForCustomer',
            data: this.order
          });
        } finally {
          this.isSaveButtonDisabled = false;
        }
        (this.$refs.countryForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
      }
    })
  }

  cancel() {
    (this.$refs.countryForm as any).resetFields();
    this.$emit('input', false);
  }

  async visibleChange(value: boolean) {
    if (!value) {
      this.$emit('input', value);
    } else {
      this.order = Util.extend(true, {}, this.$store.state.order.editCustomerShippingOrder);
      await this.loadCustomerCompanies();
      await this.getDocuments(this.order.id);
      this.shippingMethods = await this.$store.dispatch('order/shippingMethods');
      this.incoterms = await this.$store.dispatch('incoterm/lookUp');
      if (this.toEdit) {
        await this.loadRelatedCompanies(this.order.clientId)
      }
    }
  }

  async getDocuments(orderId) {
    this.documents = await this.$store.dispatch('order/getDocuments', orderId);
  }

  checkClientInput(rule, value, callback) {
    if (value == null) {
      return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Client'))));
    }
    return callback();
  }

  customerOrderRule = {
    clientId: [{
      required: true,
      validator: this.checkClientInput,
      trigger: 'change'
    }]
  }
}
