
import AbpBase from "../../../../lib/abpbase";
import {Component, Prop} from "vue-property-decorator";
import OrderDocument from "@/store/entities/ordercontext/order-document";

@Component({
  components: {}
})
export default class OrderDocumentUpload extends AbpBase {
  @Prop({default: -1}) shippingOrderId: number;
  @Prop({default: new OrderDocument()}) orderDocument: OrderDocument;
  @Prop({default: false}) disabled: boolean;

  async handleUpload(file) {
    let formData = new FormData();
    formData.append('documentId', this.orderDocument.documentId.toString());
    formData.append('id', this.orderDocument.id.toString());
    formData.append('shippingOrderId', this.orderDocument.shippingOrderId.toString());
    formData.append('file', file);
    await this.$store.dispatch({type: 'order/updateDocument', data: formData});
    this.$emit('save-success');
  }

  async previewDocument(id: number) {
    await this.$store.dispatch('order/previewDocument', id);
  }

  async download(id: number) {
    await this.$store.dispatch('order/downloadJobDocument', id);
  }

  async removeFile(id: number) {
    this.$Modal.confirm({
      title: this.L('Document'),
      content: this.L('Delete document confirmation'),
      okText: this.L('Yes'),
      cancelText: this.L('No'),
      onOk: async () => {
        await this.$store.dispatch('order/removeFile', id);
        this.$emit('save-success');
      }
    })
  }
}
